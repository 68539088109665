<div class="top">
  <a href="/" class="logo">
    <img class="logo-img" [src]="logoSrc$ | async" alt="">
    <span class="logo-text">Томский региональный центр развития талантов «Пульсар»</span>
  </a>
  <div class="controls">
    <!-- <button class="controls__btn-search" (click)="onClickSearch()">Поиск -->
    <!-- </button> -->
    <button class="controls__btn-visually-impaired" (click)="onClickVisuallyImpaired()">Версия для
      слабовидящих
    </button>
    <a class="controls__link-login" [href]="aisUrl" target="_blank" (click)="onClickLogin()">Войти</a>
  </div>
  <button class="controls__btn-menu" (click)="openMenu()">Меню
  </button>
</div>
<nav class="menu">
  <ng-container *ngFor="let nav of navs">
    <a *ngIf="nav.id !== 'Sirius'" class="menu-item" [routerLink]="nav.src" routerLinkActive="active">{{ nav.label
      }}</a>
    <a *ngIf="nav.id === 'Sirius'" class="menu-item" [href]="nav.src" target="_blank">{{ nav.label }}</a>
  </ng-container>
</nav>