<ng-container *ngIf="options  && !(isVIOn$ | async)">
  <div class="main-slider">
    <!-- <swiper-container #swiper class="swiper" pagination="true">
      <div class="swiper-slide" *ngFor="let item of options.fileItems">
        <picture>
          <img class="main-slider__img" [src]="item" alt="">
        </picture>
      </div>
    </swiper-container> -->

    <div class="swiper js-carousel-main" #carousel id="carouse">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of options.fileItems">
          <picture>
            <img class="main-slider__img" [src]="item" alt="">
          </picture>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div id="button-prev-relacionados" class="main-slider__nav swiper-button-prev main-carusel-prev">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
    <div id="button-next-relacionados" class="main-slider__nav swiper-button-next main-carusel-next">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</ng-container>