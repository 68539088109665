import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { IGalleryOptions } from "./gallery.options";

@Component({
	standalone: true,
	selector: "app-gallery",
	templateUrl: "./gallery.template.html",
	styleUrl: "./gallery.style.less",
	imports: [NgIf, NgFor, AsyncPipe],
	host: {
		class: "general-host",
	},
})
export class GalleryComponent implements OnInit, AfterViewInit {
	@ViewChild("carousel", { read: ElementRef }) container?: ElementRef;
	@Input() options: IGalleryOptions | null = null;

	isVIOn$ = new Observable<boolean>();

	constructor(private _VIService: VisuallyImpairedService) {}

	ngOnInit(): void {
		this.isVIOn$ = this._VIService.isVIOn$;
	}

	ngAfterViewInit(): void {
		if (!this.container) return;

		const carouselOptions = {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
			},
		};
		new Swiper(this.container.nativeElement, carouselOptions);
	}
}
