import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, DestroyRef, Inject, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { AIS_URL } from "@app";
import { BREAKPOINTS } from "@shared";
import { TuiDialogContext, TuiLoaderModule } from "@taiga-ui/core";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { BehaviorSubject, combineLatest, first, map, Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import { IHeaderDetails, INavs, PagesApiService } from "../../entity/pages";

@Component({
	standalone: true,
	selector: "app-menu",
	templateUrl: "./menu.template.html",
	styleUrl: "./menu.style.less",
	imports: [NgIf, AsyncPipe, TuiLoaderModule, NgForOf, NgClass],
})
export class MenuComponent implements OnInit {
	currentIndexOpenNav$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
	activeNavId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
	navsSt$: BehaviorSubject<INavs[]> = new BehaviorSubject<INavs[]>([]);
	childrenNavs$: BehaviorSubject<INavs[]> = new BehaviorSubject<INavs[]>([]);
	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	logoSrc$ = new Observable<string>();

	#aisUrl: string = inject(AIS_URL);
	#pagesApiService: PagesApiService = inject(PagesApiService);
	#router = inject(Router);
	#destroyRef = inject(DestroyRef);

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<any, any>,
		@Inject(BREAKPOINTS) private _breakpoint$: Observable<string>,
		private _VIService: VisuallyImpairedService
	) {
		this.navsSt$.next([
			...context.data,
			{
				id: "Other",
				label: "Прочее",
				src: "prochee",
				_id: 9,
			},
		]);

		this.#destroyRef.onDestroy(() => {
			this.currentIndexOpenNav$.complete();
			this.activeNavId$.complete();
			this.navsSt$.complete();
			this.childrenNavs$.complete();
			this.loading$.complete();
		});
	}

	ngOnInit(): void {
		this.setActiveNav();

		this.logoSrc$ = combineLatest([this._VIService.isVIOn$, this._VIService.theme$]).pipe(
			map(([isVIOn, theme]) => (isVIOn ? `svg/logo_${theme}.svg` : "svg/logo.svg"))
		);

		this._breakpoint$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((res) => {
			if (res === "desktop") {
				this.context.completeWith(true);
				return;
			}
		});
	}

	get aisUrl(): string {
		return this.#aisUrl;
	}

	protected onClickSearch(): void {}

	protected onClickVisuallyImpaired(): void {
		this._VIService.toggleIsOn();
		// this.context.completeWith(false);
	}

	protected onClickLogin(): void {}

	#getHeadersDetail(src: string): Observable<IHeaderDetails> {
		return this.#pagesApiService.getDetails(src).pipe(
			first(),
			map((res) => {
				if (src === "svedeniya_ob_oo") {
					res.contentItems.push({ id: 0, name: "Обратная связь", src: "feedback" });
				}
				this.childrenNavs$.next(
					res.contentItems.map((item) => ({
						id: String(item.id),
						label: item.name,
						src: item.src ? item.src : String(item.id),
						_id: item.id,
					}))
				);
				this.loading$.next(false);
				return res;
			})
		);
	}

	onToggled(nav: INavs, index: number): void {
		if (nav.children) {
			if (index === this.currentIndexOpenNav$.value) {
				this.currentIndexOpenNav$.next(null);
				return;
			}
			this.loading$.next(true);
			this.currentIndexOpenNav$.next(index);
			this.#getHeadersDetail(String(nav.src)).subscribe();
		} else {
			this.currentIndexOpenNav$.next(null);
			this.redirect(nav);
		}
	}

	redirect(item: INavs): void {
		const redirect = this.currentIndexOpenNav$.value
			? this.navsSt$.value[this.currentIndexOpenNav$.value].src + "/" + item.src
			: item.src;
		this.setActiveNav(item._id);
		this.#router.navigate(redirect.split("/"));
		this.context.completeWith(true);
	}

	setActiveNav(id?: number): void {
		if (id) {
			this.activeNavId$.next(id);
			return;
		}

		const urlArray = this.#router.url.split("/").filter((item) => item);

		let globalIndex: number = 0;
		const globalNav = this.navsSt$.value.find((item, index) => {
			globalIndex = index;
			return item.src === urlArray[0];
		});

		if (globalNav) {
			if (urlArray.length > 1) {
				this.currentIndexOpenNav$.next(globalIndex);
				this.#getHeadersDetail(String(globalNav.src)).subscribe((res) => {
					const item = res.contentItems.find((nav) => nav.src === urlArray[1]);
					this.activeNavId$.next(item?.id ?? 0);
				});
			} else {
				this.activeNavId$.next(globalNav._id ?? 0);
			}
		}
	}
}
