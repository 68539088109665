import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable, combineLatest, map } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import { ILinksPhotoOptions } from "./links-photo.options";

@Component({
	standalone: true,
	selector: "app-links-photo",
	templateUrl: "./links-photo.template.html",
	styleUrl: "./links-photo.style.less",
	imports: [NgIf, NgFor, AsyncPipe],
	host: {
		class: "general-host",
	},
})
export class LinksPhotoComponent implements OnInit {
	@Input() options: ILinksPhotoOptions | null = null;

	textOnly$ = new Observable<boolean>();

	constructor(
		private _VIService: VisuallyImpairedService,
		private _destroyRef: DestroyRef
	) {}

	ngOnInit(): void {
		this.textOnly$ = combineLatest([this._VIService.isVIOn$, this._VIService.hideImages$]).pipe(
			takeUntilDestroyed(this._destroyRef),
			map(([isVIOn, isHidingImages]) => isVIOn && isHidingImages)
		);
	}
}
