<ng-container *ngIf="options && !(isVIOn$ | async)">
  <h2 class="general-title" *ngIf="options.title">{{options.title}}</h2>
  <p class="general-subtitle" *ngIf="options.subtitle">{{options.subtitle}}</p>
  <div class="main-slider">
    <div class="swiper js-carousel-main" #carousel>
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of options.fileItems">
          <picture><img class="main-slider__img" [src]="item" alt="">
          </picture>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="main-slider__nav swiper-button-prev"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg></div>
    <div class="main-slider__nav swiper-button-next"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg></div>
  </div>
</ng-container>