import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { TFontSize, TTheme, VisuallyImpairedService } from "src/entity/visually-impaired";

@Component({
	selector: "app-visually-impaired-menu",
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule],
	templateUrl: "./visually-impaired-menu.component.html",
	styleUrl: "./visually-impaired-menu.component.less",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisuallyImpairedMenuComponent implements OnInit {
	readonly fontSizes: TFontSize[] = ["md", "lg", "xl"];
	readonly themes: TTheme[] = ["light", "blue", "dark"];

	isOpen$ = new BehaviorSubject(false);
	hideImagesControl = new FormControl(false);
	fontSize$ = new Observable<TFontSize>();

	constructor(
		private _VIService: VisuallyImpairedService,
		private _destroyRef: DestroyRef
	) {
		this._destroyRef.onDestroy(() => {
			this.isOpen$.complete();
		});
	}

	ngOnInit(): void {
		this.isOpen$ = this._VIService.isVIOn$;
		this.hideImagesControl.setValue(this._VIService.hideImages$.value);
		this.hideImagesControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
			this.toggleHideImages();
		});
		this.fontSize$ = this._VIService.fontSize$;
	}

	setFontSize(fontSize: TFontSize): void {
		this._VIService.applyFontSize(fontSize);
	}

	toggleHideImages(): void {
		this._VIService.toggleHideImages();
	}

	setTheme(theme: TTheme): void {
		this._VIService.applyTheme(theme);
	}
}
