<ng-container *ngIf="options">
    <a *ngFor="let item of options" class="card" (click)="redirect(item.src)">
        <div class="card__head">
            <img class="card__img" [src]="item.cover" alt="">
        </div>
        <p class="card__date" [style.color]="activeColor" *ngIf="item.startDate && item.endDate">
            {{stringifyDate(item.startDate)}} -
            {{stringifyDate(item.endDate)}}</p>
        <p class="card__title" *ngIf="item.name">{{item.name}}</p>
        <p class="card__class" [style.color]="activeColor">
            <tui-svg src="icon_user"></tui-svg>
            <span class="card__class-text">{{item.forWhom}}</span>
        </p>
        <p class="card__register" [style.color]="activeColor">
            <tui-svg src="icon_calendar"></tui-svg>
            <span class="card__register-text">Регистрация до {{stringifyDate(item.endDateForApplications)}}</span>
        </p>
    </a>
</ng-container>