import { NgFor, NgIf } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TuiSvgModule } from "@taiga-ui/core";
import { DateTime } from "luxon";
import { IOlympiadsOptions } from "./olympiad-grid-cards.options";

@Component({
	standalone: true,
	selector: "app-olympiad-grid-cards",
	templateUrl: "./olympiad-grid-cards.template.html",
	styleUrl: "./olympiad-grid-cards.style.less",
	imports: [NgIf, NgFor, TuiSvgModule],
})
export class OlympiadGridCardsComponent {
	@Input() options: IOlympiadsOptions[] | null = null;
	activeColor: string | null = "#000000";

	#router = inject(Router);
	#route = inject(ActivatedRoute);

	protected redirect(src: string) {
		this.#router.navigate([`./${src}`], { relativeTo: this.#route });
	}

	protected stringifyDate(date: string) {
		return DateTime.fromISO(date).toFormat("dd.MM.yyyy");
	}
}
