import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { IGalleryOptions } from "./gallery.options";

@Component({
	standalone: true,
	selector: "app-gallery-special-page-main",
	templateUrl: "./gallery.template.html",
	styleUrl: "./gallery.style.less",
	imports: [NgIf, NgFor, AsyncPipe],
	// schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GallerySpecialPageMainComponent implements OnInit, OnDestroy {
	// @ViewChild("swiper", { read: ElementRef }) swiperRef?: ElementRef<SwiperContainer>;
	@ViewChild("carousel", { read: ElementRef }) container?: ElementRef;
	@Input() options: IGalleryOptions | null = null;
	#swiper?: Swiper;

	isVIOn$ = new Observable<boolean>();

	constructor(private _VIService: VisuallyImpairedService) {}

	ngOnInit(): void {
		this.isVIOn$ = this._VIService.isVIOn$;
	}

	ngAfterViewInit(): void {
		if (!this.container) return;

		const carouselOptions = {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			loop: true,
			spaceBetween: 0,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: "#button-next-relacionados",
				prevEl: "#button-prev-relacionados",
			},
			pagination: {
				el: ".swiper-pagination",
			},
		};
		this.#swiper = new Swiper(this.container.nativeElement, carouselOptions);
	}

	changeSlide(prevOrNext: number): void {
		// if (!this.swiperRef) return;
		// if (prevOrNext === -1) {
		// 	this.swiperRef.nativeElement.swiper.slidePrev();
		// } else {
		// 	this.swiperRef.nativeElement.swiper.slideNext();
		// }
	}

	ngOnDestroy(): void {
		this.#swiper && this.#swiper.destroy();
	}
}
