import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IVideoOptions } from "./video.options";

@Component({
	standalone: true,
	selector: "app-video",
	templateUrl: "./video.template.html",
	styleUrl: "./video.style.less",
	imports: [NgIf, NgFor],
	host: {
		class: "general-host",
	},
})
export class VideoComponent {
	@Input() options: IVideoOptions | null = null;
}
