import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	selector: "app-flex-container",
	template: `
		<div class="flex-container">
			<ng-container #flexContainer></ng-container>
		</div>
	`,
	styleUrl: "./flex-container.style.less",
})
export class FlexContainerComponent {
	@ViewChild("flexContainer", { read: ViewContainerRef }) container?: ViewContainerRef;

	@Input() gap: string = "10px";

	@Input() set marginBottom(value: number) {
		this._marginBottom = `${value}px`;
	}

	protected _marginBottom: string = "0";

	constructor(public viewContainerRef: ViewContainerRef) {}
}
