<div class="head">
  <a class="logo" href="/">
    <img class="logo-img" [src]="logoSrc$ | async" alt="" />
    <span class="logo-text">Томский региональный центр развития талантов «Пульсар»</span>
  </a>
</div>
<div class="menu__nav">
  <ul>
    <li *ngFor="let item of navsSt$ | async; let i = index"
      [ngClass]="{'has-children': item.children, 'active': item._id === (activeNavId$ | async) }">
      <a (click)="onToggled(item, i)" *ngIf="item.id !== 'Sirius'">{{ item.label }}</a>
      <a [href]="item.src" target="_blank" *ngIf="item.id === 'Sirius'">{{ item.label }}</a>
      <ul *ngIf="item.children && (i === (currentIndexOpenNav$ | async)) ">
        <ng-container *ngIf="(loading$ | async)">
          <tui-loader [showLoader]="true" [overlay]="true"></tui-loader>
        </ng-container>
        <ng-container *ngIf="!(loading$ | async)">
          <li *ngFor="let subItem of childrenNavs$ | async"
            [ngClass]="{'active': subItem._id === (activeNavId$ | async) }">
            <a (click)="redirect(subItem)">{{subItem.label}}</a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
<div class="controls">
  <!-- <button class="btn-search" (click)="onClickSearch()">Поиск</button> -->
  <button class="btn-visually-impaired" (click)="onClickVisuallyImpaired()">
    Версия для слабовидящих
  </button>
  <a class="link-login" [href]="aisUrl" target="_blank" (click)="onClickLogin()">Войти</a>
</div>