import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-title',
	templateUrl: './title.template.html',
	styleUrl: './title.style.less',
	imports: [],
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {
	@Input() title: string = '';
}
