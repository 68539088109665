import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IDescriptionOptions } from "./description.options";

@Component({
	standalone: true,
	selector: "app-description",
	templateUrl: "./description.template.html",
	styleUrl: "./description.style.less",
	imports: [NgIf],
	host: {
		class: "general-host",
	},
})
export class DescriptionComponent {
	@Input() options: IDescriptionOptions | null = null;

	constructor(private sanitizer: DomSanitizer) {}

	safeHtml(html: string) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}
