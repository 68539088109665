<ng-container *ngIf="options && !(VIService.isVIOn$ | async)">
    <h2 class="general-title" *ngIf="options.title">{{options.title}}</h2>
    <p class="general-subtitle" *ngIf="options.subtitle">{{options.subtitle}}</p>
    <div class="users-container">
        <div class="personal" *ngFor="let item of options.items">
            <img class="personal__photo" [src]="item.imageUrl || srcEmptyPhoto" alt="">
            <div class="personal__info">
                <p class="personal__name">{{item.surname + ' ' + item.name + ' ' + item.patronymic }}</p>
                <p class="personal__position">{{item.subtitle}}</p>
                <pre class="personal__note">{{item.note}}</pre>
            </div>
        </div>
    </div>
</ng-container>