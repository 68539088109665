<ng-container *ngIf="options">
  <h2 class="general-title" *ngIf="options.title">{{options.title}}</h2>
  <p class="general-subtitle" *ngIf="options.subtitle">{{options.subtitle}}</p>
  <div class="inner">
    <a *ngFor="let link of options.items" class="link__item" [href]="link.url" target="_blank">
      <span class="item-wrap">
        <img class="item-img" [src]="link.thumbnail" alt="">
        <p *ngIf="textOnly$ | async" class="item-text">{{link.name}}</p>
      </span>
      <p *ngIf="!(textOnly$ | async)" class="item-text">{{link.name}}</p>
    </a>
  </div>
</ng-container>