import { AsyncPipe, NgIf } from "@angular/common";
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import {
	DescriptionComponent,
	DividerComponent,
	FilesComponent,
	GalleryComponent,
	LinksComponent,
	PhotoSecondComponent,
	UsersComponent,
	VideoComponent,
} from "@widgets";

@Component({
	selector: "app-inner-dynamic",
	standalone: true,
	imports: [NgIf, AsyncPipe],
	templateUrl: "./inner-dynamic.template.html",
	styleUrl: "./inner-dynamic.style.less",
})
export class InnerDynamicComponent implements AfterViewInit, OnChanges {
	@ViewChild("main", { read: ViewContainerRef }) dynamicContainer?: ViewContainerRef;

	@Input() content: any[] | null = null;

	viewInit: boolean = false;
	firstRender: boolean = true;

	ngAfterViewInit(): void {
		this.createDynamicComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.dynamicContainer && this.viewInit) {
			this.createDynamicComponent();
		}
	}

	createDynamicComponent() {
		if (!this.dynamicContainer) return;
		this.viewInit = true;
		this.dynamicContainer.clear();

		if (this.content) {
			for (const item of this.content) {
				if (item.type === "Gallery") {
					const galleryComponent = this.dynamicContainer.createComponent(GalleryComponent);
					galleryComponent.instance.options = item;
					galleryComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Description") {
					const descriptionComponent = this.dynamicContainer.createComponent(DescriptionComponent);
					descriptionComponent.instance.options = item;
					descriptionComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Photo") {
					const photoComponent = this.dynamicContainer.createComponent(PhotoSecondComponent);
					photoComponent.instance.options = item;
					photoComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Divider") {
					const dividerComponent = this.dynamicContainer.createComponent(DividerComponent);
					dividerComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Links") {
					const linksComponent = this.dynamicContainer.createComponent(LinksComponent);
					linksComponent.instance.options = item;
					linksComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Files") {
					const filesComponent = this.dynamicContainer.createComponent(FilesComponent);
					filesComponent.instance.options = item;
					filesComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Video") {
					const videoComponent = this.dynamicContainer.createComponent(VideoComponent);
					videoComponent.instance.options = item;
					videoComponent.changeDetectorRef.detectChanges();
				}
				if (item.type === "Users") {
					if (this.firstRender)
						item.items = item.items.reduce((acc: any, item: any) => acc.concat(item.users), []);

					const usersComponent = this.dynamicContainer.createComponent(UsersComponent);
					usersComponent.instance.options = item;
					usersComponent.changeDetectorRef.detectChanges();
				}
			}
		}

		this.firstRender = false;
	}
}
