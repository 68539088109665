<ng-container *ngIf="options">
    <div class="col">
        <p class="col-subtitle">{{label}}</p>
        <h4 class="col-title">{{options.name}}</h4>
        <div class="item">
            <span class="item-icon item-icon--location"></span>
            <span class="item-info">
                <span class="item-title">Адрес: </span><br>
                <span class="item-text">{{options.address}}</span>
            </span>
        </div>
        <div class="item">
            <span class="item-icon item-icon--phone"></span>
            <span class="item-info">
                <span class="item-title">Телефон: </span><br>
                <span class="item-text">{{options.phone}}</span>
            </span>
        </div>
        <div class="item">
            <span class="item-icon item-icon--email"></span>
            <span class="item-info">
                <span class="item-title">Адрес электронной почты: </span><br>
                <span class="item-text">{{options.email}}</span>
            </span>
        </div>
        <div class="item">
            <span class="item-icon item-icon--schedule"></span>
            <span class="item-info">
                <span class="item-title">Режим и график работы: </span><br>
                <pre class="item-text" style="white-space: break-spaces;">{{options.schedule}}</pre>
            </span>
        </div>
    </div>
</ng-container>