import { NgFor, NgIf, SlicePipe } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { Fancybox } from "@fancyapps/ui";
import { IPhotoSecondOptions } from "./photo-second.options";

@Component({
	standalone: true,
	selector: "app-photo-second",
	templateUrl: "./photo-second.template.html",
	styleUrl: "./photo-second.style.less",
	imports: [NgIf, NgFor, SlicePipe],
	host: {
		class: "general-host",
	},
})
export class PhotoSecondComponent implements AfterViewInit, OnDestroy {
	@Input() options: IPhotoSecondOptions | null = null;

	@ViewChild("gridPhotos", { read: ElementRef }) gridPhotos?: ElementRef;

	ngAfterViewInit(): void {
		if (this.gridPhotos && this.options) {
			Fancybox.bind(this.gridPhotos.nativeElement, "[data-fancybox]", {
				...Fancybox.defaults,
				Carousel: {
					transition: "slide",
				},
				showClass: "f-fadeIn",
			});
		}
	}
	ngOnDestroy(): void {
		if (this.gridPhotos) {
			Fancybox.unbind(this.gridPhotos.nativeElement);
			Fancybox.close();
		}
	}
}
