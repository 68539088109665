<div class="container">
  <div class="footer__inner">
    <a href="#" class="footer__logo">
      <img class="footer__logo-img" [src]="logoSrc$ | async" alt="" />
      <div class="footer__col">
        <span class="footer__logo-text footer__logo-text-desktop">Copyright © 2024. <br />
          Томский региональный центр <br />
          развития талантов «Пульсар».<br />
          Все права защищены</span>
        <p class="footer__copyright-mobile">
          Copyright © 2024. <br />
          Томский региональный центр <br />
          развития талантов «Пульсар».<br />
          Все права защищены
        </p>
      </div>
    </a>
    <div class="footer__menu">
      <ng-container *ngFor="let nav of localNavs">
        <ng-container *ngIf="nav">
          <a *ngIf="nav.id !== 'Sirius'" class="footer__menu-item" [routerLink]="nav.src">{{nav.label}}</a>
          <a *ngIf="nav.id === 'Sirius'" class="footer__menu-item" [href]="nav.src" target="_blank">{{nav.label}}</a>
        </ng-container>
      </ng-container>
    </div>
    <div class="footer__contacts">
      <div class="footer__chats">
        <div class="footer__chats-text">
          <a href="tel:83822515666" class="footer__chats-item">{{phone}}</a>
          <a href="'mailto:'{{email}}" class="footer__chats-item">{{email}}</a>
        </div>
        <div class="footer__chats-icons">
          <a href="https://vk.com/rcro_tomsk" target="_blank" class="footer__chats-icon footer__chats-icon--vk"></a>
          <a href="https://web.telegram.org/k/#@rcro_tomsk" target="_blank"
            class="footer__chats-icon footer__chats-icon--tg"></a>
        </div>
      </div>
      <hr class="footer__hr" />
      <div class="footer__contacts-inner">
        <p *ngFor="let adress of addresses" class="footer__contacts-item" [innerHTML]="adress"></p>
      </div>
    </div>
  </div>
</div>
<div class="footer__copyright">
  <div class="container">
    <div class="footer__copyright-inner">
      <a class="footer__copyright-link" [href]="PKDUrl" target="_blank" title="ТРЦ Пульсар. Политика ОПД.pdf">Политика
        конфиденциальности</a>
      <a class="footer__copyright-link" href="#">Разработка сайта synaptik.ru</a>
    </div>
  </div>
</div>
