<ng-container *ngIf="options">
    <div #gridPhotos>
        <div class="general__head">
            <h2 class="general__head-title" *ngIf="options.title">{{options.title}}</h2>
            <a class="general__head-link" data-fancybox="gallery" *ngIf="options.fileItems.length > 6"
                [href]="options.fileItems[0]">Смотреть все</a>
        </div>
        <p class="general-subtitle" *ngIf="options.subtitle">{{options.subtitle}}</p>
        <div class="wide-photos" *ngIf="options.displayType === 'Wide'">
            <a *ngFor="let photo of options.fileItems" data-fancybox="gallery" [href]="photo">
                <img class="wide-photos__item" [src]="photo" />
            </a>
        </div>
        <div class="photos" *ngIf="options.displayType === 'Grid'">
            <a *ngFor="let photo of options.fileItems | slice:0:6" data-fancybox="gallery" [href]="photo">
                <img [src]="photo" />
            </a>
            <a *ngFor="let photo of options.fileItems | slice:6 : options.fileItems.length" data-fancybox="gallery"
                [href]="photo" style="display: none;">
                <img [src]="photo" />
            </a>
        </div>
    </div>
</ng-container>