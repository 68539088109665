import { NgFor, NgIf } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { DateTime } from "luxon";
import { ITag } from "src/entity/pages";
import { IEventsOptions } from "./events.options";

@Component({
	standalone: true,
	selector: "app-events",
	templateUrl: "./events.template.html",
	styleUrl: "./events.style.less",
	imports: [NgIf, NgFor, RouterLink],
})
export class EventsSpecialPageMainComponent {
	@Input() options: IEventsOptions[] | null = null;

	#router = inject(Router);

	protected redirect(src: string) {
		this.#router.navigate(["sobytiya", src]);
	}

	protected getTag(tags: ITag[]) {
		return tags.map((tag) => tag.name).join(", ");
	}

	protected stringifyDate(date: string) {
		return DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat("dd.MM.yyyy") : "";
	}
}
