import { NgFor, NgIf } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TuiSvgModule } from "@taiga-ui/core";
import { DateTime } from "luxon";
import { IEducationOptions } from "./educations.options";

@Component({
	standalone: true,
	selector: "app-educations",
	templateUrl: "./educations.template.html",
	styleUrl: "./educations.style.less",
	imports: [NgIf, NgFor, TuiSvgModule],
})
export class EducationsComponent {
	@Input() options: IEducationOptions[] | null = null;
	@Input() activeColor: string | null = "#000000";

	#router = inject(Router);
	#route = inject(ActivatedRoute);

	protected redirect(src: string) {
		this.#router.navigate([`./${src}`], { relativeTo: this.#route });
	}

	protected stringifyDate(date: string) {
		return DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat("dd.MM.yyyy") : "";
	}
}
