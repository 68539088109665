import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DateFormatPipe } from "@shared";
import { IFilesOptions } from "./files.options";

@Component({
	standalone: true,
	selector: "app-files",
	templateUrl: "./files.template.html",
	styleUrl: "./files.style.less",
	imports: [NgIf, NgFor, DateFormatPipe],
	host: {
		class: "general-host",
	},
})
export class FilesComponent {
	@Input() options: IFilesOptions | null = null;

	getLabelFile(file: string): string {
		return file.split("/").pop() ?? "";
	}
}
