<div class="container menu" [class.menu_hidden]="!(isOpen$ | async)">
  <div class="menu__item font-sizes">
    <span class="menu__label">Размер шрифта:</span>
    <button class="menu__button" *ngFor="let fontSize of fontSizes" (click)="setFontSize(fontSize)"
      [class.menu__button_active]="(fontSize$ | async) === fontSize">A</button>
  </div>

  <div class="menu__item">
    <span class="menu__label">Без изображений:</span>
    <label class="switch">
      <input type="checkbox" [formControl]="hideImagesControl">
      <span class="slider round"></span>
    </label>
  </div>

  <div class="menu__item theme">
    <span class="menu__label">Цветовая схема:</span>
    <button class="menu__button" *ngFor="let theme of themes" (click)="setTheme(theme)">A</button>
  </div>
</div>