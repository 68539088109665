<ng-container *ngIf="options">
  <h3 class="title">{{options.title}}</h3>
  <div class="wrap">
    <div class="inner swiper js-carousel-links" #carouselLinks id="carouseLinks">
      <div class="swiper-wrapper">
        <a class="swiper-slide item" [href]="item.url" target="_blank" *ngFor="let item of options.items">
          <span class="item-wrap">
            <img class="item-img" [src]="item.thumbnail" alt="">
            <p *ngIf="textOnly$ | async" class="item-text">{{item.name}}</p>
          </span>
          <p *ngIf="!(textOnly$ | async)" class="item-text">{{item.name}}</p>
        </a>
      </div>
    </div>
    <div class="swiper-button-prev-a"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg></div>
    <div class="swiper-button-next-a"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.3334L4.66667 8.00002L10 2.66669" stroke="#888888" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg></div>
  </div>
</ng-container>