import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { SwiperOptions } from "swiper/types";
import { ILinksPhotoOptions } from "./links-photo.options";

@Component({
	standalone: true,
	selector: "app-links-photo-special-page-main",
	templateUrl: "./links-photo.template.html",
	styleUrl: "./links-photo.style.less",
	imports: [NgIf, NgFor, AsyncPipe],
	host: {
		class: "events",
	},
})
export class LinksPhotoSpecialPageMainComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("carouselLinks", { read: ElementRef }) container?: ElementRef;
	#swiper?: Swiper;

	@Input() options: ILinksPhotoOptions | null = null;

	textOnly$ = new Observable<boolean>();

	constructor(private _VIService: VisuallyImpairedService) {}

	ngOnInit(): void {
		this.textOnly$ = combineLatest([this._VIService.isVIOn$, this._VIService.hideImages$]).pipe(
			map(([isVIOn, isHidingImages]) => isVIOn && isHidingImages)
		);
	}

	ngAfterViewInit(): void {
		if (!this.container) return;

		const carouselOptions: SwiperOptions = {
			modules: [Navigation],
			slidesPerView: "auto",
			spaceBetween: 10,
			loop: true,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: ".swiper-button-next-a",
				prevEl: ".swiper-button-prev-a",
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
				},
				1440: {
					slidesPerView: 4,
					spaceBetween: 12,
				},
			},
		};
		this.#swiper = new Swiper(this.container.nativeElement, carouselOptions);
	}

	ngOnDestroy(): void {
		this.#swiper && this.#swiper.destroy();
	}
}
