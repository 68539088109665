import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, inject, Injector, Input, OnInit } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AIS_URL } from "@app";
import { DialogService } from "@shared";
import { PolymorpheusComponent } from "@tinkoff/ng-polymorpheus";
import { combineLatest, map, Observable } from "rxjs";
import { INavs } from "src/entity/pages";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import { MenuComponent } from "../menu/menu.component";

@Component({
	standalone: true,
	selector: "app-header",
	templateUrl: "./header.template.html",
	styleUrl: "./header.style.less",
	imports: [NgFor, RouterLink, RouterLinkActive, NgIf, AsyncPipe],
})
export class HeaderComponent implements OnInit {
	@Input() navs: INavs[] | null = [];
	logoSrc$ = new Observable<string>();

	#aisUrl: string = inject(AIS_URL);
	#dialogService = inject(DialogService);
	#injector = inject(Injector);

	get aisUrl(): string {
		return this.#aisUrl;
	}

	constructor(private _VIService: VisuallyImpairedService) {}

	ngOnInit(): void {
		this.logoSrc$ = combineLatest([this._VIService.isVIOn$, this._VIService.theme$]).pipe(
			map(([isVIOn, theme]) => (isVIOn ? `svg/logo_${theme}.svg` : "svg/logo.svg"))
		);
	}

	protected onClickLogin(): void {}

	protected openMenu(): void {
		this.#dialogService
			.open(new PolymorpheusComponent(MenuComponent, this.#injector), {
				data: this.navs,
			})
			.subscribe();
	}

	protected onClickSearch(): void {}

	protected onClickVisuallyImpaired(): void {
		this._VIService.toggleIsOn();
	}
}
