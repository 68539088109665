<ng-container *ngIf="options">
    <h2 class="general-title" *ngIf="options.title">{{options.title}}</h2>
    <p class="general-subtitle" *ngIf="options.subtitle">{{options.subtitle}}</p>
    <div class="videos">
        <div class="video" *ngFor="let video of options.items">
            <video class="video-js" controls preload="auto" [poster]="video.thumbnail" data-setup="{}">
                <source [src]="video.url" type="video/mp4" />
                <source [src]="video.url" type="video/webm" />
            </video>
        </div>
    </div>
</ng-container>