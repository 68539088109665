import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DateFormatPipe } from "@shared";
import { ILinksOptions } from "./links.options";

@Component({
	standalone: true,
	selector: "app-links",
	templateUrl: "./links.template.html",
	styleUrl: "./links.style.less",
	imports: [NgIf, NgFor, DateFormatPipe],
	host: {
		class: "general-host",
	},
})
export class LinksComponent {
	@Input() options: ILinksOptions | null = null;
}
