import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";
import { IPhotoOptions } from "./photo.options";

@Component({
	standalone: true,
	selector: "app-photo-special-page-main",
	templateUrl: "./photo.template.html",
	styleUrl: "./photo.style.less",
	imports: [NgIf, NgFor, AsyncPipe, RouterLink],
})
export class PhotoSpecialPageMainComponent implements OnInit {
	@Input() options: IPhotoOptions | null = null;
	isVIOn$ = new Observable<boolean>();

	titles: string[] = ["Наука", "Искусство", "Спорт", "Предпринимательство"];

	#hrefs: string[] = [
		"/obuchenie/nauka",
		"/obuchenie/iskusstvo",
		"/obuchenie/sport",
		"/obuchenie/predprinimatelstvo",
	];

	constructor(private _VIService: VisuallyImpairedService) {}

	ngOnInit(): void {
		this.isVIOn$ = this._VIService.isVIOn$;
	}

	getHref(index: number): string {
		return index > 4 ? "" : this.#hrefs[index];
	}
}
