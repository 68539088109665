import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { combineLatest, map, Observable } from "rxjs";
import { VisuallyImpairedService } from "src/entity/visually-impaired";

@Component({
  standalone: true,
  selector: "app-footer",
  templateUrl: "./footer.template.html",
  styleUrl: "./footer.style.less",
  imports: [NgFor, NgIf, RouterLink, AsyncPipe],
})
export class FooterComponent implements OnInit {
  @Input() set navs(items: { src: string; id: string; label: string; _id: number }[] | null) {
    if (!items) return;

    this.localNavs = [
      ...items,
      {
        id: "Other",
        label: "Прочее",
        src: "prochee",
        _id: 9,
      },
    ];
  }

  localNavs: { src: string; id: string; label: string }[] | null = [];

  logoSrc$ = new Observable<string>();
  PKDUrl = "docx/ТРЦ Пульсар. Политика ОПД.pdf";

  email: string = "secretary@education.tomsk.ru";
  addresses: string[] = [
    "634050, Томская область, г. Томск, ул. Татарская, 16",
    "634055, Томская область, г. Томск,<br/> пр-т Развития, 3 (Особая экономическая зона)",
    "634522, Томская область, Томский район, окр. с. Калтай",
  ];
  phone: string = "8 (382-2) 51-56-66,";

  constructor(private _VIService: VisuallyImpairedService) { }

  ngOnInit(): void {
    this.logoSrc$ = combineLatest([this._VIService.isVIOn$, this._VIService.theme$]).pipe(
      map(([isVIOn, theme]) => (isVIOn ? `svg/logo_${theme}.svg` : "svg/logo.svg"))
    );
  }
}
