<ng-container *ngIf="options">
  <ng-container *ngIf="options.length">
    <div class="events__header">
      <h2 class="events__title">События</h2>
      <a class="events__link-all" routerLink="/events">Смотреть все</a>
    </div>
    <div class="events__inner">
      <a *ngFor="let event of options" class="events__item" (click)="redirect(event.src)">
        <span class="events__item-head">
          <img class="events__item-img" [src]="event.cover" alt="" />
        </span>
        <div class="events__item-body">
          <span class="events__item-tag"><span *ngFor="let direction of event.directions"
              [style.color]="direction.color">{{ direction.name }}
            </span></span>
          <p class="events__item-text">{{ event.title }}</p>
          <p class="events__item-date">{{ stringifyDate(event.createdAt) }} <span *ngIf="stringifyDate(event.createdAt)"
              class="events__item-separator"></span>{{
            getTag(event.tags)
            }}
          </p>
        </div>
      </a>
    </div>
  </ng-container>
</ng-container>