import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, DestroyRef, inject, Input } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { VisuallyImpairedService } from "@entity";
import { combineLatest, map } from "rxjs";
import { IUsersOptions } from "./users.options";

@Component({
	standalone: true,
	selector: "app-users",
	templateUrl: "./users.template.html",
	styleUrl: "./users.style.less",
	imports: [NgIf, NgFor, AsyncPipe],
	host: {
		class: "general-host",
	},
})
export class UsersComponent {
	@Input() options: IUsersOptions | null = null;

	srcEmptyPhoto: string = "empty.jpg";

	VIService = inject(VisuallyImpairedService);
	#destroyRef = inject(DestroyRef);

	textOnly$ = combineLatest([this.VIService.isVIOn$, this.VIService.hideImages$]).pipe(
		takeUntilDestroyed(this.#destroyRef),
		map(([isVIOn, isHidingImages]) => isVIOn && isHidingImages)
	);
}
