import { Component, Input, ViewContainerRef } from '@angular/core';

@Component({
	selector: 'app-grid-container',
	template: ` <ng-container #gridContainer></ng-container> `,
	host: {
		'style.display': 'grid',
		'[style.grid-gap]': 'gridGap',
		'[style.grid-template-columns]': 'gridTemplateColumns',
	},
})
export class GridContainerComponent {
	@Input() gridGap: string = '10px';

	@Input() gridTemplateColumns: string = '1fr 1fr';

	constructor(public viewContainerRef: ViewContainerRef) {}
}
