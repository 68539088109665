<ng-container *ngIf="options">
  <div class="category">
    <ng-container *ngIf="!(isVIOn$ | async)">
      <a class="category__item" *ngFor="let item of options.fileItems; let i = index" [routerLink]="getHref(i)">
        <img [src]="item" alt="">
      </a>
    </ng-container>

    <ng-container *ngIf="isVIOn$ | async">
      <div *ngFor="let item of titles; let i = index" class="course" [routerLink]="getHref(i)">
        <span>{{item}}</span>
      </div>
    </ng-container>
  </div>
</ng-container>