import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IContactOptions } from './contact.options';

@Component({
	standalone: true,
	selector: 'app-contacts-special-page-main',
	templateUrl: './contacts.template.html',
	styleUrl: './contacts.style.less',
	imports: [NgIf],
})
export class ContactsSpecialPageMainComponent {
	@Input() options: IContactOptions | null = null;

	get label(): string {
		if (this.options) {
			if (this.options?.title === 'Контакты образовательной организации') return 'Образовательная организация';
			if (this.options?.title === 'Контакты учредителя образовательной организации') return 'Учредитель';
			return this.options?.name;
		}
		return '';
	}
}
