import { AsyncPipe, NgIf } from "@angular/common";
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
	DescriptionComponent,
	DividerComponent,
	FilesComponent,
	GalleryComponent,
	LinksComponent,
	LinksPhotoComponent,
	PhotoSecondComponent,
	UsersComponent,
	VideoComponent,
} from "@widgets";
import { BehaviorSubject, first } from "rxjs";
import { IHeaderContent } from "src/entity/pages";

@Component({
	standalone: true,
	selector: "app-inner-layout",
	templateUrl: "./inner-layout.template.html",
	styleUrl: "./inner-layout.style.less",
	imports: [NgIf, AsyncPipe],
})
export class InnerLayoutComponent implements AfterViewInit {
	@ViewChild("main", { read: ViewContainerRef }) dynamicContainer?: ViewContainerRef;

	content$: BehaviorSubject<IHeaderContent | null> = new BehaviorSubject<IHeaderContent | null>(null);

	firstRender = true;

	constructor(private _route: ActivatedRoute) {
		this._route.data.pipe(first()).subscribe((data) => {
			this.content$.next(
				data["content"] ? { ...data["content"], content: JSON.parse(data["content"].content) } : null
			);
		});
	}

	ngAfterViewInit(): void {
		this.createDynamicComponent();
	}

	createDynamicComponent() {
		if (!this.dynamicContainer) return;

		if (this.content$.value) {
			for (const content of this.content$.value.content) {
				if (content.type === "Gallery") {
					const galleryComponent = this.dynamicContainer.createComponent(GalleryComponent);
					galleryComponent.instance.options = content;
					galleryComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Description") {
					const descriptionComponent = this.dynamicContainer.createComponent(DescriptionComponent);
					descriptionComponent.instance.options = content;
					descriptionComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Photo") {
					const photoComponent = this.dynamicContainer.createComponent(PhotoSecondComponent);
					photoComponent.instance.options = content;
					photoComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Divider") {
					const dividerComponent = this.dynamicContainer.createComponent(DividerComponent);
					dividerComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Links") {
					const linksComponent = this.dynamicContainer.createComponent(LinksComponent);
					linksComponent.instance.options = content;
					linksComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Files") {
					const filesComponent = this.dynamicContainer.createComponent(FilesComponent);
					filesComponent.instance.options = content;
					filesComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Video") {
					const videoComponent = this.dynamicContainer.createComponent(VideoComponent);
					videoComponent.instance.options = content;
					videoComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "LinksPhoto") {
					const linksPhotoComponent = this.dynamicContainer.createComponent(LinksPhotoComponent);
					linksPhotoComponent.instance.options = content;
					linksPhotoComponent.changeDetectorRef.detectChanges();
				}
				if (content.type === "Users") {
					if (this.firstRender)
						content.items = content.items.reduce((acc: any, item: any) => acc.concat(item.users), []);

					const usersComponent = this.dynamicContainer.createComponent(UsersComponent);
					usersComponent.instance.options = content;
					usersComponent.changeDetectorRef.detectChanges();
				}
			}
		}

		this.firstRender = false;
	}
}
